import React from "react"
import Layout from "../components/layout"
import "./services.css"
import Services from "../components/services"
import Contact from "../components/contact"
import SingleService from "../components/singleService"
import websites from "../../static/services_websites.png"
import apps from "../../static/services_applications.png"
import digital from "../../static/services_digital.png"
import branding from "../../static/services_branding.png"
import Dict from "../langs/dict"

export default () => {
  const dict = new Dict("en")
  return (
    <Layout
      lang="en"
      pageUrl="/services"
      seoTitle="Services: web sites, apps, digital strategy and design"
      seoDescription="Our team of experts work accross all digital mediums using only the latest tools and technologies. What do you need?"
    >
      <div id="services-background">
        <Services lang="en" />
      </div>
      <div id="single-services-background">
        <SingleService
          textPosition="left"
          image={websites}
          hl="green-hl"
          title={dict.getTrans("services1_title")}
          text={dict.getTrans("services1_text")}
          reference={dict.getTrans("services1_reference")}
          referenceProject={dict.getTrans("services1_reference_project")}
          linkto={dict.getTrans("services1_linkto")}
        />
        <SingleService
          textPosition="right"
          image={apps}
          hl="pink-hl"
          title={dict.getTrans("services2_title")}
          text={dict.getTrans("services2_text")}
          reference={dict.getTrans("services2_reference")}
          referenceProject={dict.getTrans("services2_reference_project")}
          linkto={dict.getTrans("services2_linkto")}
        />
        <SingleService
          textPosition="left"
          image={digital}
          hl="orange-hl"
          title={dict.getTrans("services3_title")}
          text={dict.getTrans("services3_text")}
          reference={dict.getTrans("services3_reference")}
          referenceProject={dict.getTrans("services3_reference_project")}
          linkto={dict.getTrans("services3_linkto")}
        />
        <SingleService
          textPosition="right"
          image={branding}
          hl="blue-hl"
          title={dict.getTrans("services4_title")}
          text={dict.getTrans("services4_text")}
          reference={dict.getTrans("services4_reference")}
          referenceProject={dict.getTrans("services4_reference_project")}
          linkto={dict.getTrans("services4_linkto")}
        />
      </div>
      <div id="contact-services-background">
        <Contact lang="en" />
      </div>
    </Layout>
  )
}
